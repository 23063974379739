import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as XLSX from 'xlsx';

function AddressControl() {
  const [addresses, setAddresses] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [isTest, setIsTest] = useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  function getAddresses() {
    setIsLoading(true);
    axios
      .get("https://ai-similarity-backend.geovision.link/addresses-control-score")
      .then((response) => {
        setAddresses(response.data.addresses);
        console.log(response.data);
      })
      .catch((error) => console.error("Hata:", error))
      .finally(() => setIsLoading(false));
  }

  function getCompanyNames() {
    setIsLoading(true);
    axios
      .get("https://ai-similarity-backend.geovision.link/company-names")
      .then((response) => {
        const companyNames = response.data.addresses.map(
          (address) => address.name
        );
        setCompanyNames(companyNames);
      })
      .catch((error) => console.error("Error:", error))
      .finally(() => setIsLoading(false));
  }

  function getCompanysAll() {
    setIsLoading(true);
    setIsTest(true);
    axios
      .get("https://ai-similarity-backend.geovision.link/company-infos", {
        params: {
          customer_name: selectedCompanyName,
        },
      })
      .then((response) => {
        setAddresses(response.data.addresses);
        setData(response.data.addresses);
      })
      .catch((error) => console.error("Error:", error))
      .finally(() => setIsLoading(false));
  }

  function handleGetData() {
    setSelectedCompanyName("");
    setIsTest((prev) => !prev);
    console.log(isTest);
    if (isTest) {
      getAddressesTest();
    } else {
      getAddresses();
    }
  }

  function getAddressesTest() {
    setIsLoading(true);
    axios
      .get("https://ai-similarity-backend.geovision.link/addresses-control-score-test")
      .then((response) => {
        setAddresses(response.data.addresses);
        console.log(response.data);
      })
      .catch((error) => console.error("Hata:", error))
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    getCompanyNames();
    if (selectedCompanyName) {
      getCompanysAll();
    }
  }, [selectedCompanyName]);

  function handlePrediction() {
    setIsLoading(true);
    axios
      .get("https://ai-compare.geovision.link/api/compare", {
        params: { customerCode: selectedCompanyName },
      })
      .then((response) => {
        console.log("Prediction Response:", response.data);
      })
      .catch((error) => console.error("Error in Prediction Request:", error))
      .finally(() => setIsLoading(false));
  }

  function handleListRequest() {
    setIsLoading(true);
    axios
      .get("https://ai-compare.geovision.link/api/list", {
        params: { customerCode: selectedCompanyName },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => console.error("Error in List Request:", error))
      .finally(() => setIsLoading(false));
  }

  const columns = [
    { field: 'gvgUid', headerName: 'GVG UID', width: 150 },
    { field: 'outletOid', headerName: 'Outlet OID', width: 150 },
    { field: 'customerSignName', headerName: 'Customer Sign Name', width: 200 },
    { field: 'gvgSignName', headerName: 'GVG Sign Name', width: 200 },
    { field: 'customerLegalName', headerName: 'Customer Legal Name', width: 300 },
    
    { field: 'gvgLegalName', headerName: 'GVG Legal Name', width: 300 },
    { field: 'controlName', headerName: 'Control Name', width: 150, type: 'boolean' },
    { field: 'customerAddress', headerName: 'Customer Address', width: 180 },
    { field: 'gvgAddress', headerName: 'GVG address', width: 180 },
    { field: 'controlAddress', headerName: 'Control Address', width: 150, type: 'boolean' },

    { field: 'customerLongitude', headerName: 'Customer Longitude', width: 180 },
    { field: 'customerLatitude', headerName: 'Customer Latitude', width: 180 },
  
  
    { field: 'gvgLongitude', headerName: 'GVG Longitude', width: 180 },
    { field: 'gvgLatitude', headerName: 'GVG Latitude', width: 180 },
    {field: 'mesafe' , headerName: 'Mesafe', width: 180},

    { field: 'controlCompound', headerName: 'Control Compound', width: 150, type: 'boolean' },
    { field: 'token', headerName: 'Token', width: 150 },
    { field: 'wrong', headerName: 'Wrong', width: 150 },

  ];

  const columnsTest = [
    { field: "gvg_uid", headerName: "Gvg Uid", width: 100 },
    { field: "address_1", headerName: "Adres 1", width: 300 },
    { field: "address_2", headerName: "Adres 2", width: 300 },
    { field: "address_similarity", headerName: "Adres Benzerliği", width: 180 },
    { field: "address_similarity_2", headerName: "Adres Benzerliği_2", width: 180 },
    { field: "address_similarity_3", headerName: "Adres Benzerliği_3", width: 180 },
    { field: "control", headerName: "Kontrol", width: 120 },
    { field: "customer_name", headerName: "Müşteri İsim", width: 150 },
    { field: "gvg_name", headerName: "Gvg İsim", width: 150 },
    { field: "name_similarity", headerName: "İsim Benzerliği", width: 180 },
  ];

  function handleControlChange(address, controlValue) {
    const updatedAddresses = addresses.map((addr) => 
      addr.address_1 === address.address_1 ? { ...addr, control: controlValue } : addr
    );
    setAddresses(updatedAddresses);

    if (selectedCompanyName) {
      writeToModelData(address, controlValue);
    } else {
      sendControlRequest(address, controlValue);
    }
  }

  function sendControlRequest(address, controlValue) {
    const requestData = {
      address_1: address.address_1,
      address_2: address.address_2,
      control: controlValue,
    };

    axios
      .post("https://ai-similarity-backend.geovision.link/control", requestData)
      .then((response) => {
        if (response.status === 200) {
          console.log("Kontrol isteği başarıyla gönderildi.");
          // İsteği gönderdikten sonra tabloyu yenilemeye gerek yok
        } else {
          console.error("Kontrol isteği gönderilirken bir hata oluştu.");
        }
      })
      .catch((error) =>
        console.error("Kontrol isteği gönderilirken bir hata oluştu:", error)
      );
  }

  function writeToModelData(address, controlValue) {
    axios
      .post("https://ai-similarity-backend.geovision.link/write-to-model-data", {
        address_1: address.address_1,
        gvg_uid: address.gvg_uid,
        control: controlValue,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Veri model_data tablosuna başarıyla yazıldı.");
        } else {
          console.error("Model_data tablosuna yazılırken bir hata oluştu.");
        }
      })
      .catch((error) =>
        console.error("Model_data tablosuna yazılırken bir hata oluştu:", error)
      );
  }

  function handleExcelExport() {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Addresses");
    XLSX.writeFile(wb, "addresses.xlsx");
  }

  return (
    <div style={{ height: 800, width: "auto" }}>
      <div
        style={{
          height: 40,
          width: "3500px",
          backgroundColor: "#3f51b5",
          color: "white",
          paddingLeft: 20,
          paddingTop: 3,
        }}
      >
        {" "}
        Adres / İsim Benzerliği{" "}
        {selectedCompanyName
          ? selectedCompanyName
          : isTest
          ? "Eğitim"
          : "Test"}{" "}
        Verisi
      </div>
      <Box sx={{ paddingTop: 2 }}>
        <Button
          onClick={() => {
            handleGetData();
          }}
        >
          {isTest ? "Test" : "Training"}
        </Button>
        <FormControl sx={{ width: '150px', marginRight: 2 }}>
          <InputLabel id="demo-simple-select-label">Firma</InputLabel>
          <Select
            value={selectedCompanyName}
            onChange={(e) => { setIsTest(false); setSelectedCompanyName(e.target.value) }}
            label='Firma'
          >
            {companyNames.map((companyName) => (
              <MenuItem key={companyName} value={companyName}>
                {companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handlePrediction} disabled={!selectedCompanyName}>
          Tahminle
        </Button>
        <Button variant="contained" onClick={handleListRequest} disabled={!selectedCompanyName}>
          Görüntüle
        </Button>
        <Button variant="contained" onClick={handleExcelExport}>
          Excel'e Aktar
        </Button>
      </Box>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGrid slots={{ toolbar: GridToolbar }}
        sx={{width:"3500px"}}
          rows={data.map((item, index) => ({ id: index, ...item }))}
          columns={columns}
          pageSize={10}
          autoWidth
          loading={isLoading}
        />
      )}
    </div>
  );
}

export default AddressControl;
