import logo from './logo.svg';
import AddressControl from './AddressControl';

function App() {
  return (
   <AddressControl/>
  );
}

export default App;
